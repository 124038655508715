import React from 'react';
import { NavLink } from 'react-router-dom';

export default function IconMenuItem({ item }) {
  return (
    <NavLink
      to={item.href}
      onClick={() => {
        // setCurrentPage(item?.name);
      }}
      className={({ isActive }) =>
        isActive
          ? 'flex flex-col items-center py-2 text-sm font-medium  text-center border-b-2 border-purple-500'
          : 'flex flex-col items-center py-2 text-sm font-medium  text-center text-gray-500  hover:text-gray-800'
      }
    >
      <item.icon className='h-6 w-6' aria-hidden='true' />

      <div className='whitespace-pre-line leading-tight'>{item.name}</div>
    </NavLink>
  );
}
