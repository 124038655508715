import { postFormContentQuestion } from '../../../../../../../services/forms/formContentQuestionService';

export const handleAddOption = ({ uuid, fields, setFields, update, ref }) =>
  postFormContentQuestion({
    question: uuid,
    content: `Option ${fields.length + 1}`,
  }).then((res) => {
    setFields((prev) => [...prev, res]);
    update();
    setTimeout(() => {
      if (ref.current[res.uuid]) {
        ref.current[res.uuid].focus();
        ref.current[res.uuid].select();
      }
    }, 0);
  });
// content?.fields = newFields; // если нужно синхронизировать с внешним состоянием
