import React, { useCallback, useRef, useState } from 'react';
import { patchFormContentQuestion } from '../../../../../../../services/forms/formContentQuestionService';
import DragableOptions from './OptionLayouts/DragableOptions';
import OptionBlank from './OptionLayouts/OptionBlank';
import { handleAddOption } from './OptionUtils';

export default function Checkboxes({ content, selected, update }) {
  const [fields, setFields] = useState(content?.questionselectcontent);
  const inputRefs = useRef({});
  const focusRefs = useRef({});

  const saveForm = useCallback(
    ({ value, field, uuid }) => {
      patchFormContentQuestion(uuid, { [field]: value })?.then((res) => {
        setFields(
          fields?.map((f) => {
            if (f.uuid === uuid) {
              f[field] = value;
            }
            return f;
          }),
        );
      });
    },
    [fields],
  );

  const debounceSaveData = useCallback(
    (value, ref, field, uuid) => {
      // Отправить запрос через 1 секунду после последнего ввода
      if (ref.current) {
        clearTimeout(ref.current);
      }

      ref.current = setTimeout(() => {
        if (!value) return;

        saveForm({ uuid, value, field });
        console.log(value);
      }, 1000);
    },
    [saveForm],
  );

  const handleFocus = (uuid) => {
    if (focusRefs.current) {
      focusRefs.current[uuid].style.width = '100%';
    }
  };

  const handleBlur = (uuid) => {
    if (focusRefs.current) {
      focusRefs.current[uuid].style.width = '0%';
    }
  };

  return (
    <div className='flex flex-col gap-4'>
      <DragableOptions
        fields={fields}
        setFields={setFields}
        selected={selected}
      >
        {fields?.map((field) => {
          return (
            <OptionBlank
              key={field?.uuid}
              field={field}
              fields={fields}
              setFields={setFields}
              update={update}
              selected={selected}
            >
              <input
                className='w-5 min-w-[1.25rem] rounded-md bg-white 
            focus:outline-none cursor-pointer'
                type='checkbox'
                checked={field.is_correct}
                onChange={() => {
                  patchFormContentQuestion(field?.uuid, {
                    is_correct: !field.is_correct,
                  })?.then(() => {
                    const newFields = fields.map((f) => {
                      if (field.uuid !== f.uuid) {
                        return f;
                      } else {
                        f.is_correct = !field.is_correct;
                        return f;
                      }
                    });
                    setFields(newFields);
                    update();
                  });
                }}
              />
              <div className='w-full'>
                <input
                  ref={(el) => (inputRefs.current[field.uuid] = el)}
                  defaultValue={field?.content}
                  onBlur={(e) => {
                    handleBlur(field.uuid);
                    if (e.target.value === '') {
                      e.target.value = field?.content;
                    }
                  }}
                  onFocus={() => handleFocus(field.uuid)}
                  onChange={(e) => {
                    debounceSaveData(
                      e?.target?.value,
                      inputRefs.current[field.uuid],
                      'content',
                      field.uuid,
                    );
                  }}
                  className={`${
                    selected && 'hover:border-b'
                  }  focus:outline-none  w-full`}
                />
                <div className='flex flex-row justify-center text-transparent'>
                  <div
                    ref={(el) => (focusRefs.current[field.uuid] = el)}
                    className='w-0 border-b text-violet-700 border-current transition-[width] delay-200'
                  />
                </div>
              </div>
            </OptionBlank>
          );
        })}
      </DragableOptions>
      <div className={`flex flex-row ml-6 gap-2 ${!selected && 'hidden'}`}>
        <input
          className='w-5 rounded-md bg-white 
            focus:outline-none
            transition duration-200 mt-1 align-top float-left mr-2 cursor-pointer'
          type='checkbox'
          readOnly
          checked={false}
        />
        <div>
          <input
            onClick={(e) => {
              if (e.detail > 1) return;
              handleAddOption({
                uuid: content.uuid,
                fields,
                setFields,
                update,
                ref: inputRefs,
              });
            }}
            onDoubleClick={() => {}}
            className='hover:border-b focus:outline-none w-full'
            placeholder={'Add option'} // to translate
            readOnly
            onBlur={() => {
              handleBlur(content.uuid);
            }}
            onFocus={() => {
              handleFocus(content.uuid);
            }}
          />

          <div className='flex flex-row justify-center text-transparent'>
            <div
              ref={(el) => (focusRefs.current[content.uuid] = el)}
              className='w-0 border-b text-violet-700 border-current transition-[width] delay-100'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
