import React,{useContext, useState} from 'react';
import CreatePost from './CreatePost/CreatePost'
import Posts from './Posts/Posts'
import { UserContext } from '../../utils/contexts/UserContext';


export default function Feed() {
    const [postCreated,setPostCreated] = useState(false)
    const { user } = useContext(UserContext);


    function handlePostCreated(){
        setPostCreated(!postCreated)
    }

    return (
        <div className='flex justify-center flex-col  items-center'>
            {user.roles[0].name === 'PROJECTADMIN' && <CreatePost onPostCreated={handlePostCreated} user={user}/> }
            <Posts postCreated={postCreated} onPostCreated={handlePostCreated} user={user}/>
        </div>
    )

}
