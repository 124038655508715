import { CheckIcon, ClockIcon, PlayIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from './Table';
import TableOfSchools from './TableOfSchools';

export default function ProjectStats({ school, overall, data, user }) {
  const [t] = useTranslation();

  return (
    <div className='flex flex-col gap-4 p-4'>
      <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 text-white'>
        <div
          className='rounded-xl bg-success bg-opacity-80 flex flex-col 
              gap-2 p-4 justify-between'
        >
          <div className='text-2xl font-bold'>{t('projects.in_progress')}</div>
          <div className='flex flex-row justify-between gap-2'>
            <div className='font-semibold'>
              <span className='text-4xl'>
                {school === 'all'
                  ? overall?.inprogress
                  : data?.find((d) => d?.uuid === school)?.inprogress}
              </span>{' '}
              {t('studyplans.projects')}
            </div>
            <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
              <PlayIcon className='h-6 text-success opacity-80' />
            </div>
          </div>
        </div>

        <div
          className='rounded-xl bg-error flex flex-col 
              gap-2 p-4 w-full justify-between'
        >
          <div className='text-2xl font-bold'>{t('projects.waiting')}</div>
          <div className='flex flex-row justify-between gap-2'>
            <div className='font-semibold'>
              <span className='text-4xl'>
                {school === 'all'
                  ? overall?.waiting
                  : data?.find((d) => d?.uuid === school)?.waiting}
              </span>{' '}
              {t('studyplans.projects')}
            </div>
            <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
              <ClockIcon className='h-6 text-error' />
            </div>
          </div>
        </div>

        <div
          className='rounded-xl bg-primary flex flex-col 
              gap-2 p-4 w-full justify-between'
        >
          <div className='text-2xl font-bold'>{t('projects.checking')}</div>
          <div className='flex flex-row justify-between gap-2'>
            <div className='font-semibold'>
              <span className='text-4xl'>
                {school === 'all'
                  ? overall?.checking
                  : data?.find((d) => d?.uuid === school)?.checking}
              </span>{' '}
              {t('studyplans.projects')}
            </div>
            <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
              <ClockIcon className='h-6 text-primary' />
            </div>
          </div>
        </div>

        <div
          className='rounded-xl bg-success flex flex-col 
              gap-2 p-4 w-full justify-between'
        >
          <div className='text-2xl font-bold'>{t('projects.accepted')}</div>
          <div className='flex flex-row justify-between gap-2'>
            <div className='font-semibold'>
              <span className='text-4xl'>
                {school === 'all'
                  ? overall?.accepted
                  : data?.find((d) => d?.uuid === school)?.accepted}
              </span>{' '}
              {t('studyplans.projects')}
            </div>
            <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
              <CheckIcon className='h-6 text-success' />
            </div>
          </div>
        </div>
      </div>

      <div className='rounded-xl border shadow-lg flex flex-col gap-2 p-6'>
        <div>{t('stats.this_year_achievement')}</div>
        <div className='font-bold text-xl'>
          <span className='text-4xl'>
            {school === 'all'
              ? overall?.submissions
              : data?.find((d) => d?.uuid === school)?.submissions}
          </span>{' '}
          {t(t('stats._submissions'))}
        </div>
      </div>

      <Table school={school} />
      <div className='border-2 border-gray-400 mt-2'></div>
      <TableOfSchools roles={user?.roles} school={school} />
    </div>
  );
}
