import {
  CheckIcon,
  ClipboardDocumentIcon,
  MapIcon,
  PuzzlePieceIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GeneralTable from './GeneralTable';

export default function GeneralStats({ school, data, user }) {
  const [t] = useTranslation();

  return (
    <div className='flex flex-col gap-4 p-4'>
      <div className='grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-4 text-white'>
        <div
          className='rounded-xl bg-success flex flex-col 
              gap-2 p-4 justify-between'
        >
          <div className='text-2xl font-bold'>{t('Projects Accepted')}</div>
          <div className='flex flex-row justify-between gap-2'>
            <div className='font-semibold'>
              <span className='text-4xl'>{data?.projects_accepted}</span>{' '}
              {t('studyplans.projects')}
            </div>
            <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
              <PuzzlePieceIcon className='h-6 text-success' />
            </div>
          </div>
        </div>

        <div
          className='rounded-xl bg-error flex flex-col 
              gap-2 p-4 w-full justify-between'
        >
          <div className='text-2xl font-bold'>{t('Project Sets Finished')}</div>
          <div className='flex flex-row justify-between gap-2'>
            <div className='font-semibold'>
              <span className='text-4xl'>{data?.project_sets_finished}</span>{' '}
              {t('sets')}
            </div>
            <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
              <ClipboardDocumentIcon className='h-6 text-error' />
            </div>
          </div>
        </div>

        <div
          className='rounded-xl bg-primary flex flex-col 
              gap-2 p-4 w-full justify-between'
        >
          <div className='text-2xl font-bold'>{t('Courses Finished')}</div>
          <div className='flex flex-row justify-between gap-2'>
            <div className='font-semibold'>
              <span className='text-4xl'>{data?.courses_finished}</span>{' '}
              {t('courses')}
            </div>
            <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
              <ClipboardDocumentIcon className='h-6 text-primary' />
            </div>
          </div>
        </div>

        <div
          className='rounded-xl bg-yellow-500 flex flex-col 
              gap-2 p-4 w-full justify-between'
        >
          <div className='text-2xl font-bold'>{t('Students Passed Tests')}</div>
          <div className='flex flex-row justify-between gap-2'>
            <div className='font-semibold'>
              <span className='text-4xl'>{data?.students_using_tests}</span>{' '}
              {t('students')}
            </div>
            <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
              <CheckIcon className='h-6 text-yellow-500' />
            </div>
          </div>
        </div>

        <div
          className='rounded-xl bg-orange-500 flex flex-col 
              gap-2 p-4 w-full justify-between'
        >
          <div className='text-2xl font-bold'>
            {t('Students Using Roadmap')}
          </div>
          <div className='flex flex-row justify-between gap-2'>
            <div className='font-semibold'>
              <span className='text-4xl'>{data?.students_using_roadmap}</span>{' '}
              {t('students')}
            </div>
            <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
              <MapIcon className='h-6 text-orange-500' />
            </div>
          </div>
        </div>
      </div>
      <GeneralTable selectedSchool={school} />
    </div>
  );
}
