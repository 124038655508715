import { ArrowRightIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';

const General = ({ info }) => {
  const [descriptionDetails, setDescriptionDetails] = useState(false);
  return (
    <div className='flex flex-row gap-4'>
      <div className='flex flex-row gap-4 w-3/4'>
        <div className='flex flex-col gap-4 w-2/5'>
          <div className='border rounded-xl divide-y'>
            {info?.ratingInformation?.map((r) => (
              <div key={r?.information} className='flex flex-col px-8 py-3'>
                <div>{r?.information}</div>
                <div>{r?.name}</div>
              </div>
            ))}
          </div>
          <div className='flex flex-col gap-2 border rounded-xl p-4'>
            {info?.keyFacts?.map((k) => (
              <div key={k?.icon} className='flex flex-row gap-3 items-center'>
                <ArrowRightIcon className='h-4 shrink-0 text-primary' />
                <div>{k?.description}</div>
              </div>
            ))}
          </div>
        </div>
        <div className='border rounded-xl p-4 w-3/5'>
          {descriptionDetails
            ? info?.fullDescription?.replace(/(<([^>]+)>)/gi, '')
            : info?.description?.replace(/(<([^>]+)>)/gi, '')}
          {!descriptionDetails && (
            <div
              className='text-primary underline mt-4 cursor-pointer'
              onClick={() => setDescriptionDetails(true)}
            >
              Подробнее
            </div>
          )}
        </div>
      </div>
      <div className='flex flex-col items-center border rounded-lg gap-4 w-1/4 p-4'>
        <div className='text-primary font-semibold text-lg'>
          {'Тип стипендий'}
        </div>
        <div className='flex flex-col gap-1 w-full'>
          {info?.scholarship?.map((s) => (
            <div key={s} className='text-gray-600'>
              <p className='bg-gray-100 py-3 px-4 rounded-xl inline-block'>
                {s}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default General;
