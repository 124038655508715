import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getStudyPlan,
  patchStudyPlan,
} from '../../services/studyPlans/studyPlanService';
import bgImage from '../../assets/images/bg.jpg';
import { Button } from '../../components/forms/Buttons/Button';
import { useTranslation } from 'react-i18next';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  ClockIcon,
  DocumentTextIcon,
  MagnifyingGlassIcon,
  PlayIcon,
  PlusCircleIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import Input from '../../components/forms/Inputs/InputBil';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { getProjects } from '../../services/projects/projectService';
import DeleteModal from '../../components/modals/DeleteModal/DeleteModal';
import {
  deleteStudyPlanSection,
  patchStudyPlanSection,
  postStudyPlanSection,
} from '../../services/studyPlans/studyPlanSectionService';
import RichTextEditor from '../../components/forms/Inputs/RichTextEditor';

const EditStudyPlan = () => {
  const [t] = useTranslation();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [studyPlan, setStudyPlan] = useState();
  const [sectionIndex, setSectionIndex] = useState(0);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingStudyPlans, setLoadingStudyPlans] = useState(false);
  const [setTitle, setSetTitle] = useState('');
  const [setDescription, setSetDescription] = useState('');
  const [sections, setSections] = useState([
    { study_plan: '', title: '', description: '', projects: [] },
  ]);
  const [open, setOpen] = useState(false);
  const [deleteUuid, setDeleteUuid] = useState();
  const [deleteIndex, setDeleteIndex] = useState();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingSections, setLoadingSections] = useState(false);

  // useEffect(() => {
  //   console.log('sections', sections);
  // }, [sections]);

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        getProjects({ search: inputValue }).then((res) =>
          resolve(res?.results.map((r) => ({ label: r?.name, value: r }))),
        );
      }, 1000);
    });

  const getStudyPlanData = () => {
    setLoadingStudyPlans(true);
    getStudyPlan(uuid)
      .then((res) => {
        // console.log('res', res);
        setStudyPlan(res);
        setSetTitle(res?.title);
        setSetDescription(res?.description);
        if (res?.sections?.length) {
          setSections(res?.sections);
        }
      })
      .catch(() => {})
      .finally(() => setLoadingStudyPlans(false));
  };

  useEffect(() => {
    if (uuid) {
      getStudyPlanData();
    }
    // eslint-disable-next-line
  }, [uuid]);
  return (
    <div>
      {loadingStudyPlans ? (
        <div className='pt-12'>
          <SimpleLoader className={'h-12'} />
        </div>
      ) : (
        <div className='flex flex-col'>
          <div
            className='h-48 flex flex-row'
            style={{
              backgroundImage: `url(${studyPlan?.background ?? bgImage})`,
              backgroundPosition: 'center',
            }}
          >
            <div className='w-1/3 px-4 py-8'>
              <Button
                text={t('buttons.back')}
                color='secondary-solid'
                Icon={ChevronLeftIcon}
                onClick={() => navigate('/study-plans/')}
              />
            </div>
            <div className='text-white w-2/3 flex flex-col h-full justify-end gap-8 px-8 py-8'>
              <div className='text-2xl lg:text-4xl font-medium pl-4'>
                {t('studyplans.project_set_constructor')}
              </div>
              <div className='flex flex-row justify-end'>
                <Button
                  color={'success'}
                  text={t('buttons.save')}
                  onClick={() => {
                    if (
                      setTitle !== studyPlan?.title ||
                      setDescription !== studyPlan?.description
                    ) {
                      if (!setTitle || !setDescription) {
                        toast.error(t('roadmap.required_fields_empty'));
                        return;
                      }
                      setLoadingCreate(true);
                      const body = new FormData();
                      body.append('title', setTitle);
                      body.append('description', setDescription);

                      patchStudyPlan(uuid, body)
                        .then((res) => {})
                        .catch(() => {})
                        .finally(() => setLoadingCreate(false));
                    }

                    const promises = [];
                    for (let i = 0; i < sections?.length; i++) {
                      if (
                        !sections?.[i]?.title ||
                        !sections?.[i]?.description
                      ) {
                        toast.error(t('roadmap.required_fields_empty'));
                        return;
                      }
                      const body = {};
                      body['title'] = sections?.[i]?.title;
                      body['description'] = sections?.[i]?.description;
                      body['order'] = i + 1;
                      body['projects'] = sections?.[i]?.projects?.map(
                        (p) => p?.uuid,
                      );
                      if (sections?.[i]?.uuid) {
                        promises.push(
                          patchStudyPlanSection(sections?.[i]?.uuid, body)
                            .then((res) => res?.results)
                            .catch(() => {}),
                        );
                      } else {
                        body['study_plan'] = uuid;
                        promises.push(
                          postStudyPlanSection(body)
                            .then((res) => res?.results)
                            .catch(() => {}),
                        );
                      }
                    }
                    setLoadingSections(true);
                    Promise.all(promises)
                      .then((res) => {
                        toast.success(t('roadmap.saved_successfully'));
                        navigate('/study-plans');
                      })
                      .catch(() => {})
                      .finally(() => setLoadingSections(false));
                  }}
                  loading={loadingCreate || loadingSections}
                />
              </div>
            </div>
          </div>

          <div className='flex lg:flex-row md:flex-row flex-col'>
            <div className='lg:w-1/3 md:w-1/3 px-4'>
              <div
                className='rounded-xl border shadow-lg py-4 px-6 flex flex-col divide-y-2
                w-full bg-white lg:-mt-12 md:-mt-12 -mt-4 font-medium lg:text-lg'
              >
                <div
                  className={`py-2 flex flex-row items-center gap-2 cursor-pointer ${
                    sectionIndex === -1 ? 'font-bold pl-2' : ''
                  }`}
                  onClick={() => setSectionIndex(-1)}
                >
                  <DocumentTextIcon className='h-4' />
                  {t('studyplans.overview')}
                </div>
                {sections?.map((s, index) => (
                  <div
                    key={index}
                    className={`w-full py-3 flex flex-row items-center gap-2 leading-tight ${
                      sectionIndex === index ? 'font-bold pl-2' : ''
                    } justify-between`}
                  >
                    <div className='flex flex-row items-center gap-2 cursor-pointer'>
                      <div className='flex flex-col gap-1'>
                        {index > 0 && (
                          <ChevronUpIcon
                            className='h-4'
                            onClick={() => {
                              setSections((prev) => {
                                let temp = [...prev];
                                [temp[index], temp[index - 1]] = [
                                  temp[index - 1],
                                  temp[index],
                                ];
                                return temp;
                              });
                            }}
                          />
                        )}
                        {index < sections?.length - 1 && (
                          <ChevronDownIcon
                            className='h-4'
                            onClick={() => {
                              setSections((prev) => {
                                let temp = [...prev];
                                [temp[index], temp[index + 1]] = [
                                  temp[index + 1],
                                  temp[index],
                                ];
                                return temp;
                              });
                            }}
                          />
                        )}
                      </div>
                      <div
                        onClick={() => setSectionIndex(index)}
                        className='w-full'
                      >
                        {s?.title
                          ? s?.title
                          : `${t('studyplans.section')} ${index + 1}`}
                      </div>
                    </div>
                    <TrashIcon
                      className={`h-5 cursor-pointer text-error ${
                        loadingDelete && 'animate-pulse'
                      }`}
                      onClick={() => {
                        if (s?.uuid) {
                          setOpen(true);
                          setDeleteUuid(s?.uuid);
                          setDeleteIndex(index);
                        } else {
                          setSections((prev) => {
                            let temp = [...prev];
                            temp.splice(index, 1);
                            return temp;
                          });
                        }
                      }}
                    />
                  </div>
                ))}
                <div
                  className={`py-3 flex flex-row items-center gap-2 leading-tight justify-between`}
                >
                  <div
                    className='flex flex-row items-center gap-2 cursor-pointer'
                    onClick={() =>
                      setSections((prev) => {
                        let temp = [...prev];

                        temp.push({
                          study_plan: '',
                          title: '',
                          description: '',
                          projects: [],
                        });
                        return temp;
                      })
                    }
                  >
                    <PlusCircleIcon className='text-success h-5' />
                    {`${t('studyplans.add_new_section')}`}
                  </div>
                </div>
              </div>
            </div>
            <div className='lg:w-2/3 md:w-2/3 py-6 px-4'>
              {sectionIndex === -1 ? (
                <div className='flex flex-col gap-4'>
                  <Input
                    label={t('studyplans.project_set_title')}
                    onChange={(e) => {
                      setSetTitle(e.target.value);
                    }}
                    value={setTitle}
                    required
                  />
                  <RichTextEditor
                    inputStyles='h-80'
                    required
                    label={t('studyplans.project_set_description')}
                    value={setDescription}
                    onChange={(e) => setSetDescription(e)}
                  />
                  {/* <TextArea
                    label={t('studyplans.project_set_description')}
                    value={setDescription}
                    onChange={(e) => setSetDescription(e.target.value)}
                    required
                    rows={5}
                  /> */}
                </div>
              ) : (
                <div className='flex flex-col gap-4'>
                  <Input
                    label={t('studyplans.section_title')}
                    value={sections?.[sectionIndex]?.title}
                    onChange={(e) =>
                      setSections((prev) => {
                        let temp = [...prev];
                        temp[sectionIndex]['title'] = e.target.value;
                        return temp;
                      })
                    }
                    required
                  />
                  <RichTextEditor
                    inputStyles='h-80'
                    required
                    label={t('studyplans.project_set_description')}
                    value={sections?.[sectionIndex]?.description}
                    onChange={(e) =>
                      setSections((prev) => {
                        let temp = [...prev];
                        temp[sectionIndex]['description'] = e;
                        return temp;
                      })
                    }
                  />
                  {/* <TextArea
                    label={t('studyplans.section_description')}
                    value={sections?.[sectionIndex]?.description}
                    onChange={(e) =>
                      setSections((prev) => {
                        let temp = [...prev];
                        temp[sectionIndex]['description'] = e.target.value;
                        return temp;
                      })
                    }
                    required
                    rows={5}
                  /> */}
                  <div className='flex flex-row gap-1 items-center mt-16'>
                    <MagnifyingGlassIcon className='h-5' />
                    <AsyncSelect
                      cacheOptions
                      loadOptions={promiseOptions}
                      placeholder={t('studyplans.start_typing_to_find_project')}
                      className='w-full'
                      onChange={(e) =>
                        setSections((prev) => {
                          let temp = [...prev];
                          temp?.[sectionIndex]?.['projects'].push(e.value);
                          return temp;
                        })
                      }
                    />
                  </div>
                  {sections?.[sectionIndex]?.projects?.length ? (
                    <div className='rounded-2xl border'>
                      <div className='bg-gray-200 rounded-t-2xl py-2 px-4'>
                        {t('studyplans.projects')}
                      </div>
                      <table className='table-auto w-full'>
                        <tbody>
                          {sections[sectionIndex]?.projects?.map(
                            (p, pIndex) => (
                              <tr
                                className='border-b cursor-pointer hover:bg-gray-100'
                                key={p?.uuid}
                              >
                                <td className='pl-2 pr-1 py-3'>
                                  {p?.status === 'in-progress' ? (
                                    <div className='text-xs whitespace-nowrap text-success flex flex-row gap-1 items-center'>
                                      <PlayIcon className='h-4' />
                                      <span className='whitespace-nowrap hidden lg:block'>
                                        {t('projects.in_progress')}
                                      </span>
                                    </div>
                                  ) : p?.status === 'waiting' ? (
                                    <div className='text-xs whitespace-nowrap text-error flex flex-row gap-1 items-center'>
                                      <ClockIcon className='h-4' />
                                      <span className='whitespace-nowrap hidden lg:block'>
                                        {t('projects.waiting')}
                                      </span>
                                    </div>
                                  ) : p?.status === 'checking' ? (
                                    <div className='text-xs whitespace-nowrap text-primary flex flex-row gap-1 items-center'>
                                      <ClockIcon className='h-4' />
                                      <span className='whitespace-nowrap hidden lg:block'>
                                        {t('projects.checking')}
                                      </span>
                                    </div>
                                  ) : p?.status === 'declined' ? (
                                    <div className='text-xs whitespace-nowrap text-error flex flex-row gap-1 items-center'>
                                      <XMarkIcon className='h-4' />
                                      <span className='whitespace-nowrap hidden lg:block'>
                                        {t('projects.declined')}
                                      </span>
                                    </div>
                                  ) : p?.status === 'accepted' ? (
                                    <div className='text-xs whitespace-nowrap text-success flex flex-row gap-1 items-center'>
                                      <CheckIcon className='h-4' />
                                      <span className='whitespace-nowrap hidden lg:block'>
                                        {t('projects.accepted')}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className='text-xs whitespace-nowrap flex flex-row gap-1 items-center'>
                                      <div
                                        className='h-4 w-4 rounded-full bg-secondary 
                              flex flex-row justify-center items-center shrink-0'
                                      >
                                        <div className='h-[0.6rem] w-[0.6rem] rounded-full bg-white'></div>
                                      </div>
                                      <span className='whitespace-nowrap hidden lg:block'>
                                        {t('projects.not_started')}
                                      </span>
                                    </div>
                                  )}
                                </td>
                                <td
                                  className='px-1 lg:text-base text-sm'
                                  onClick={() =>
                                    navigate(`${'/projects/' + p?.uuid}`)
                                  }
                                >
                                  {p?.name}
                                </td>
                                <td className='px-1 font-light text-sm'>
                                  {p?.skill?.name}
                                </td>
                                <td
                                  className={`text-sm pl-1 pr-2 ${
                                    p?.level === 'Beginner'
                                      ? 'text-primary'
                                      : p?.level === 'Easy'
                                      ? 'text-success'
                                      : p?.level === 'Medium'
                                      ? 'text-warning'
                                      : p?.level === 'Hard'
                                      ? 'text-error'
                                      : 'text-secondary'
                                  }`}
                                >
                                  {p?.level}
                                </td>
                                <td
                                  onClick={() => {
                                    setSections((prev) => {
                                      let temp = [...prev];
                                      temp?.[sectionIndex]?.projects?.splice(
                                        pIndex,
                                        1,
                                      );
                                      return temp;
                                    });
                                  }}
                                >
                                  <TrashIcon className='h-4 text-error' />
                                </td>
                              </tr>
                            ),
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : undefined}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <DeleteModal
        open={open}
        setOpen={setOpen}
        proceed={() => {
          setLoadingDelete(true);
          deleteStudyPlanSection(deleteUuid)
            .then((res) => {
              toast.success(t('roadmap.deleted_successfully'));
              setDeleteUuid(null);
            })
            .catch(() => {})
            .finally(() => setLoadingDelete(false));

          setSections((prev) => {
            let temp = [...prev];
            temp.splice(deleteIndex, 1);
            return temp;
          });
        }}
      />
    </div>
  );
};

export default EditStudyPlan;
