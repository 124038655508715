import React, { useContext, useEffect, useState } from 'react';
import { Button } from '../../components/forms/Buttons/Button';
import { PlusIcon } from '@heroicons/react/24/outline';
import TableOfConferences from './TableOfConferences';
import { useNavigate } from 'react-router-dom';
import { getMyVideoConferences } from '../../services/videoConference/videoConferenceService';
import { UserContext } from '../../utils/contexts/UserContext';
import VideoConferenceInfoModal from './VideoConferenceInfoModal';

export default function VideoConference() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [myVideoConferences, setMyVideoConferences] = useState([]);
  const [selectedVideoConference, setSelectedVideoConference] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getMyVideoConferences().then((res) => {
      setMyVideoConferences(res.results);
    });

    return () => {};
  }, []);

  return (
    <div className='flex flex-col gap-4 h-full'>
      <VideoConferenceInfoModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        selectedVideoConference={selectedVideoConference}
        setSelectedVideoConference={setSelectedVideoConference}
      />
      <div>
        <h1 className='text-xl font-semibold'>Video Conferences</h1>
      </div>
      {user?.roles?.some((r) => r?.name === 'PROJECTADMIN') && (
        <div className='flex flex-row justify-end'>
          <Button
            text='Create conference'
            color='success-simple'
            Icon={PlusIcon}
            onClick={() => {
              navigate('create');
            }}
          />
        </div>
      )}
      <div className='flex flex-col gap-2'>
        <p className='text-base font-semibold'>Upcoming conferences:</p>
        <TableOfConferences
          data={myVideoConferences}
          number={3}
          status={'upcoming'}
          setOpenModal={setOpenModal}
          setSelectedVideoConference={setSelectedVideoConference}
        />
      </div>
      {/* <div className='flex flex-col gap-2'>
        <p className='text-base font-semibold'>Completed conferences:</p>
        <TableOfConferences number={5} status={'completed'} />
      </div> */}
    </div>
  );
}
