import React from 'react';
import { Button } from '../../components/forms/Buttons/Button';
import moment from 'moment';
import { EyeIcon } from '@heroicons/react/24/outline';

export default function TableOfConferences({
  number,
  status,
  data,
  setOpenModal,
  setSelectedVideoConference,
}) {
  return (
    <div
      className={`border-t-2 border-2 rounded-md ${
        status === 'upcoming' && ' border-t-green-600'
      }`}
    >
      <table className='table-auto w-full'>
        <thead>
          <tr className='border-b border-gray-200'>
            <th className='px-4 py-2 text-center text-gray-500 font-normal'>
              #
            </th>
            <th className='px-4 py-2 text-left text-gray-500 font-normal'>
              Name
            </th>
            <th className='px-4 py-2 text-left text-gray-500 font-normal'>
              Description
            </th>
            <th className='px-4 py-2 text-left text-gray-500 font-normal'>
              Date
            </th>
            {/* <th className='px-4 py-2 text-left text-gray-500 font-normal'>
              hours left
            </th> */}
            <th className='px-4 py-2 text-left text-gray-500 font-normal'></th>
          </tr>
        </thead>
        <tbody>
          {data.map((vc, i) => (
            <tr
              key={i}
              className='border-b border-gray-200 even:bg-gray-100  text-sm'
            >
              <td className='px-4 py-2 text-center'>#{i + 1}</td>
              <td className='px-4 py-2 text-left'>{vc?.name}</td>
              <td className='px-4 py-2 text-left'>{vc?.banner_text}</td>
              <td className='px-4 py-2 text-left font-semibold'>
                {moment(vc?.start_time).format('MMM DD, HH:mm')}
              </td>
              {/* <td className='px-4 py-2 text-left'>{'30 days'}</td> */}
              <td className='px-4 py-2 text-left'>
                {status === 'upcoming' && (
                  <Button
                    className='max-h-8'
                    Icon={EyeIcon}
                    color='primary'
                    onClick={() => {
                      setSelectedVideoConference(vc);
                      setOpenModal(true);
                    }}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
