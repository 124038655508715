import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getColorForProjectLevel } from '../../utils/helpers/helpers';
import { FILTER_SELECT_STYLE } from '../../utils/constants/constants';
import { patchProjectAssignment } from '../../services/submissions/projectAssignmentService';
import Pagination from '../../components/Pagination/Pagination';
import Select from '../../components/forms/Select/Select';
import { Link } from 'react-router-dom';

export default function SelectReviewerTable({
  tab,
  data,
  updateTable,
  mentors,
  page,
  onPageChange,
  totalPages,
  count,
}) {
  return (
    <div className='w-full flex flex-col gap-4 border rounded-md mt-8'>
      <table className='table-auto w-full'>
        <thead>
          <tr className='border-b border-gray-200'>
            <th className='px-4 py-2 text-center text-gray-500 font-normal'>
              Submission
            </th>
            <th className='px-4 py-2 text-left text-gray-500 font-normal'>
              Student
            </th>
            <th className='px-4 py-2 text-left text-gray-500 font-normal'>
              School
            </th>
            <th className='px-4 py-2 text-left text-gray-500 font-normal'>
              Project
            </th>
            {/* <th className='px-4 py-2 text-left text-gray-500 font-normal border-r'>
              Status
            </th> */}
            <th className='px-4 py-2 text-left text-gray-500 font-normal'>
              Date
            </th>
            <th className='px-4 py-2 text-left text-gray-500 font-normal'>
              Subject
            </th>
            <th className='px-4 py-2 text-left text-gray-500 font-normal'>
              Difficulty
            </th>
            <th className='px-4 py-2 text-left text-gray-500 font-normal'>
              Reviewer
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, i) => (
            <tr
              key={i}
              className='border-b border-gray-200 even:bg-gray-100  text-sm'
            >
              <td className='px-4 py-2 text-center'>#{i + 1}</td>
              <td className='px-4 py-2 text-left'>
                <Link
                  to={`/profile/${item?.student?.uuid}`}
                  className='hover:underline cursor-pointer'
                >
                  {item?.student?.first_name + ' ' + item?.student?.last_name}
                </Link>
              </td>
              <td className='px-4 py-2 text-left'>{item?.organization}</td>
              <td className='px-4 py-2 text-left font-semibold'>
                {item?.project?.name}
              </td>
              {/* <td className='px-4 py-2 text-left text-blue-600'>
                {item?.student?.project?.name}
              </td> */}
              <td className='px-4 py-2 text-left'>
                {moment(item?.created).format('MMM DD, yyyy')}
              </td>
              <td className='px-4 py-2 text-left'>
                {item?.project?.skill?.name}
              </td>
              <td
                className={`px-4 py-2 text-center  ${getColorForProjectLevel(
                  item?.project?.level,
                )}`}
              >
                {item?.project?.level ? item?.project?.level : '-'}
              </td>

              <td className='px-4 py-2  text-left'>
                {tab === 0 ? (
                  <Select
                    customStyles={FILTER_SELECT_STYLE}
                    options={mentors}
                    onChange={(e) => {
                      patchProjectAssignment(item?.uuid, {
                        mentor: e.value,
                      }).then(() => {
                        updateTable();
                        toast.success('Success');
                      });
                    }}
                  />
                ) : (
                  <Select
                    customStyles={FILTER_SELECT_STYLE}
                    options={[{ label: 'No mentor', value: null }, ...mentors]}
                    onChange={(e) => {
                      patchProjectAssignment(item?.uuid, {
                        mentor: e.value,
                      }).then(() => {
                        toast.success('Success');
                        updateTable();
                      });
                    }}
                    value={item?.mentor?.uuid}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
        count={count}
      />
    </div>
  );
}
