import bilAPI from  '../api'

export const postRoadmapSubtaskAi = (body) => {
    return new Promise((resolve, reject) => {
      try {
        bilAPI
          .post(`/roadmap/ai_subtasks/`, body)
          .then((res) => {
            resolve(res?.data);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (error) {
        reject('system error', error);
      }
    });
  };