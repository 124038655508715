import React from 'react';
import Plyr from 'plyr-react';
import './plyr.css';
import { extractVideoID } from '../../../../../utils/helpers/helpers';

const VideoContent = ({ data }) => {
  const videoSrc = {
    type: 'video',
    sources: [
      {
        src: extractVideoID(data?.url),
        provider: 'youtube',
      },
    ],
  };

  const options = {
    autoplay: false,
    playsinline: true,
  };

  return (
    <p className='flex flex-col w-full'>
      <span>{'Video Content'}</span>
      <span className='text-sm'>
        <span>Video:</span>{' '}
        <a
          className='text-primary hover:underline'
          href={data.url}
          target='_blank'
          rel='noreferrer'
        >
          {`${data.url}`}
        </a>
      </span>
      <div className='w-1/2 '>
        <Plyr source={videoSrc} options={options} />
      </div>
    </p>
  );
};
export default VideoContent;
