import React, { useEffect, useRef, useState } from 'react';

import {
  deleteResultDescriptionFile,
  patchResultDescriptionFile,
} from '../../../../services/tests/resultService';

import { Button } from '../../../../components/forms/Buttons/Button';
import {
  CheckIcon,
  DocumentArrowDownIcon,
  DocumentArrowUpIcon,
  PencilSquareIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import Input from '../../../../components/forms/Inputs/Input';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Switch } from '@headlessui/react';

const FileCard = ({ file, refreshData, close }) => {
  const fileRef = useRef();
  const [edit, setEdit] = useState(false);

  const {
    register,
    formState: { errors },
    reset,
    control,
    handleSubmit,
  } = useForm();

  useEffect(() => {
    reset();
  }, [close, reset]);

  const handleFileChange = (e) => {
    e.preventDefault();
    if (!window.confirm('Are you sure you want to update the file?')) {
      return;
    }

    if (e.target.files[0].type !== 'application/pdf') {
      toast.error(
        'Жүктелген файл PDF болуы керек! / Загружаемый вами файл должен быть PDF!',
        'error',
      );
      return;
    }
    if (e.target.files[0].size > 1048576) {
      toast.error(
        'Жүктелген файл 1 мегабайттан аспау керек! / Загружаемый вами файл не должен превышать 1 МБ',
        'error',
      );
      return;
    }
    let doc = e.target.files[0];
    const body = new FormData();
    body.append('file', doc);

    patchResultDescriptionFile({ uuid: file.uuid, body })
      ?.then(() => {
        toast.success('File success updated');
        refreshData();
      })
      ?.catch(() => toast.error('File update error'));
  };

  const onSubmit = (data) => {
    patchResultDescriptionFile({ uuid: file.uuid, body: data }).then(() => {
      refreshData();
    });
    setEdit(false);
  };
  return (
    <form
      className='flex flex-row gap-2 border p-4 rounded-lg items-end w-full justify-between'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='flex flex-row gap-4 items-end'>
        <Input
          className='flex flex-col items-start'
          errorMsg={errors?.result?.message}
          required
          disabled={!edit}
          label='Name'
          {...register('name', {
            required: 'Name is required field',
            value: file.name,
          })}
        />
        <div className='flex flex-row items-center gap-2'>
          <DocumentArrowUpIcon
            title={edit ? null : 'Disable while not in edit mode'}
            className={`h-9 cursor-pointer ${
              edit
                ? 'text-blue-500 hover:bg-primary bg-blue-100'
                : 'text-slate-500 hover:bg-secondary bg-slate-100'
            } hover:text-white rounded-lg p-1.5`}
            onClick={() => fileRef.current.click()}
          />
          <input
            disabled={!edit}
            hidden
            type='file'
            ref={fileRef}
            onChange={handleFileChange}
          />
          {file.file && (
            <a href={file.file} target='_blank' rel='noreferrer'>
              <DocumentArrowDownIcon className='h-9 cursor-pointer text-blue-500 hover:bg-primary bg-blue-100 hover:text-white rounded-lg p-1.5' />
            </a>
          )}
          <Controller
            control={control}
            name='add_screen'
            defaultValue={file.add_screen}
            render={({ field }) => (
              <Switch
                disabled={!edit}
                title={edit ? 'Screenshot' : 'Disable while not in edit mode'}
                checked={field.value}
                onChange={field.onChange}
                className={`${
                  field.value ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className='sr-only'>Enable screenshot</span>
                <span
                  className={`${
                    field.value ? 'translate-x-6' : 'translate-x-1'
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            )}
          />
        </div>
      </div>

      <div className='flex flex-row gap-2'>
        {edit ? (
          <>
            <Button
              Icon={XMarkIcon}
              onClick={() => {
                setEdit(false);
              }}
              type='button'
            />
            <Button color='success' Icon={CheckIcon} type='submit' />
          </>
        ) : (
          <>
            <Button
              Icon={PencilSquareIcon}
              onClick={() => {
                setEdit(true);
              }}
              type='button'
            />
            <Button
              Icon={TrashIcon}
              color='error'
              onClick={() => {
                deleteResultDescriptionFile({
                  uuid: file.uuid,
                })?.then(() => {
                  refreshData();
                });
              }}
              type='button'
            />
          </>
        )}
      </div>
    </form>
  );
};

export default FileCard;
