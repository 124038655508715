import React, { useState } from 'react';
import { Button } from '../../../../components/forms/Buttons/Button';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import { exportResult } from '../../../../services/tests/resultService';
import jsPDF from 'jspdf';

const Export = ({ result, file, fileName, text, params = {}, screen_ref }) => {
  const [loadingFile, setLoadingFile] = useState(false);
  const handleGeneratePdf = () => {
    const letterWidth = 8.5 * 72;
    const letterHeight = 11 * 72;

    const doc = new jsPDF({
      format: [letterWidth, letterHeight],
      unit: 'pt',
    });
    const contentWidth = screen_ref.current.offsetWidth;
    // const contentHeight = screen_ref.current.offsetHeight;

    const scaleX = letterWidth / contentWidth;
    // const scaleY = letterHeight / contentHeight;
    // const scale = Math.min(scaleX, scaleY);

    return new Promise((resolve, reject) => {
      doc.html(screen_ref.current, {
        html2canvas: {
          useCORS: true,
          allowTaint: true,
          scale: scaleX,
        },
        callback: (doc) => {
          let blob = doc.output('blob');
          handle(blob)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
              setLoadingFile(false);
            });
        },
        margin: [20, 0, 20, 0],
        autoPaging: 'text',
      });
    });

    // html2canvas(screen_ref.current).then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF('p', 'pt', 'letter');

    //   const scaleX = canvas.width / letterWidth;

    //   pdf.addImage(imgData, 'PNG', 0, 0, letterWidth, letterHeight / scaleX);
    //   // pdf.save('my_document.pdf');
    //   let blob = pdf.output('blob');
    //   handle(blob);
    // });
  };

  const handle = (reportScreen) => {
    const body = new FormData();
    if (reportScreen) {
      body.append('reportScreen', reportScreen);
    }
    Object.keys(params).forEach((k) => {
      body.append(k, params[k]);
    });

    return new Promise((resolve, reject) => {
      exportResult({ uuid: result, body })
        .then((res) => {
          const blobFile = new Blob([res], {
            type: 'text/plain',
          });
          const url = window.URL.createObjectURL(blobFile);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return (
    <Button
      type='success-dark'
      Icon={DocumentArrowDownIcon}
      text={text}
      loading={loadingFile}
      disabled={loadingFile}
      onClick={() => {
        setLoadingFile(true);
        (screen_ref && file.add_screen
          ? handleGeneratePdf(screen_ref, result)
          : handle()
        ).finally(() => setLoadingFile(false));
      }}
    />
  );
};

export default Export;
